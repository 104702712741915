import { useFormContext } from 'react-hook-form';
import FormContainer from '../../../components/layout/FormContainer';
import { Box } from '@mui/material';
import SelectApplications from '../../../components/common/SelectApplications';
import Select from '../../../components/common/Select';
import { TIKTOK_ADS_AD_LIST } from '../../../constants';
import { AdAccountSelect } from './TikTokFormComponents';

const AdSettings = () => {
  const { setValue } = useFormContext();

  const listMapper = ({ name, code }) => ({
    label: name,
    value: code,
  });

  return (
    <FormContainer title={'Choose App and Ad Type'}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, justifyContent: 'start', width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
          <SelectApplications name={'app'} onChange={() => {
            setValue('adAccounts', '');
          }} />
          <Select
            name="adType"
            inputLabel="Ad Type"
            options={TIKTOK_ADS_AD_LIST.map(listMapper)}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
          <Box sx={{ flex: 1 }}><AdAccountSelect /></Box>
          <Box sx={{ flex: 1 }}></Box>
        </Box>
      </Box>
    </FormContainer>
  );
};

export default AdSettings;