import { useFormContext } from 'react-hook-form';
import useRowsCreatives from '../../creatives/hooks/useRowsCreatives';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import TextField from '../../../components/common/TextField';
import { DataGrid } from '@mui/x-data-grid';
import { PacksWithCreatives, SelectAdTypes } from './FacebookFormComponents';
import Checkbox from '../../../components/common/Checkbox';
import type { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import SuspenseErrorFallback from '../../../components/common/SuspenseErrorFallback';
import FormContainer from '../../../components/layout/FormContainer';

const COLUMNS = [
  { field: 'creative_id', headerName: 'Creative ID', width: 100 },
  { field: 'name', headerName: 'Name', width: 450 },
  { field: 'app', headerName: 'App', width: 100 },
  { field: 'creative_type', headerName: 'Type', width: 100 },
  { field: 'created', headerName: 'Created', width: 100 },
  { field: 'roi', headerName: 'ROI 7 days', width: 100 },
  { field: 'spend', headerName: 'Spend 7 days', width: 100 },
];

const CreativesForm: FC = () => {
  const { watch, setValue, trigger, formState: { errors } } = useFormContext();
  const { rows, isLoading, isSuccess, isError, error } = useRowsCreatives({
    params: {
      network: 'Facebook',
      display_metrics: true,
    },
  });
  const selectedCreatives = watch('selectedCreatives') as string[];
  const isCreativesFieldError = errors.selectedCreatives?.message as string | undefined;
  const isPackFieldError = errors.packsWithCreatives?.message as string | undefined;
  const selectedOnly = watch('selectedCreativesOnly') as string[];
  const packsWithCreatives = watch('packsWithCreatives') as string[][] | null;

  const filteredRows = selectedOnly
    ? rows?.filter(row => selectedCreatives.includes(row.id))
    : rows;

  const onSelectionModelChange = useCallback(
    (rows: GridRowSelectionModel) => {
      const packExists: number = packsWithCreatives?.findIndex((pack: string[]) => pack?.sort()?.join('') === (rows as string[])?.sort()?.join('')) ?? -1;
      const activePacksWithCreatives = packExists > -1 ? packExists : null;
      setValue('activePacksWithCreatives', activePacksWithCreatives);
      setValue('selectedCreatives', rows);
    },
    [packsWithCreatives, setValue],
  );

  useEffect(() => {
    void trigger('selectedCreatives');
    void trigger('adType');
    void trigger('packsWithCreatives');
  }, [trigger, selectedCreatives]);

  const handleAdType = useCallback(() => {
    setValue('selectedCreatives', []);
    setValue('packsWithCreatives', []);
    setValue('activePacksWithCreatives', '');
  }, [setValue]);

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Choose Creatives'}
        message={(error as {
          cause?: { error?: string }
        })?.cause?.error ?? 'An error occurred while fetching creatives'} />
    );
  }

  return (
    <FormContainer title={'Choose Creatives'} error={[isCreativesFieldError ?? '', isPackFieldError ?? '']}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
          <PacksWithCreatives />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2, width: '100%' }}>
          <Box sx={{
            display: 'flex',
            gap: 2,
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'nowrap',
            maxWidth: '50%',
          }}>
            <TextField
              name={'filterCreativeByName'}
              placeholder={'Search by name'}
            />
            <Checkbox name={'selectedCreativesOnly'} label={'Selected only'}
                      controlLabelSx={{ whiteSpace: 'nowrap' }} />
          </Box>
          <Box sx={{ flex: 1, maxWidth: '300px' }}>
            <SelectAdTypes handleChange={handleAdType} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={filteredRows ?? []}
              columns={COLUMNS}
              checkboxSelection
              rowSelectionModel={isSuccess ? selectedCreatives : []}
              loading={isLoading}
              onRowSelectionModelChange={onSelectionModelChange}
              hideFooterPagination
            />
          </Box>
        </Box>
      </Box>
    </FormContainer>
  );
};

export default CreativesForm;