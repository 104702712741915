import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { tikTokSchema } from '../validation/tiktok.schema';
import { Suspense, useCallback, useMemo } from 'react';
import { useCreateTikTokAd } from '../api/createTikTokAd';
import { BID_STRATEGIES, DEFAULT_TARGET_CPA, DEFAULT_TARGET_ROAS } from '../../../constants';
import CircularProgress from '../../../components/common/CircularProgress';
import useJobStatusCheck from '../../../hooks/useJobStatusCheck';
import { queryClient } from '../../../lib/react-query';
import { JobResultTable } from './TikTokFormComponents';
import StepperPaper from '../../../components/layout/StepperPaper';
import FormNavigation from '../../../components/layout/FormNavigation';
import useStepper from '../../../hooks/useStepper';
import AdSettings from './AdSettings';
import ChooseCampaign from './ChooseCampaign';
import ChooseCreative from './ChooseCreative';
import CreateAd from './CreateAd.jsx';

const TikTokForm = () => {
  const initialSteps = useMemo(
    () => [
      { title: 'Ad Settings', component: AdSettings },
      { title: 'Choose Campaign', component: ChooseCampaign },
      { title: 'Choose Creative', component: ChooseCreative },
      { title: 'Create Ad', component: CreateAd },
    ],
    [],
  );
  const {
    currentStep,
    resetSteps,
    prevStep,
    nextStep,
    StepComponent,
  } = useStepper({ initialSteps, defaultStep: 0 });

  const {
    data: jobStatusResponse,
    runJubStatusCheck,
    isLoading: isJobStatusChecking,
    isFinished: isJobStatusFinished,
    clearJobStatus,
  } = useJobStatusCheck();

  const formMethods = useForm({
    resolver: yupResolver(tikTokSchema), // object schemas for data, it helps us validate our data to make sure that it matches the schemas
    defaultValues: {
      app: '',
      adType: 'SMART_AD',
      adAccounts: '',
      selectedCampaigns: [],
      selectedCreatives: [],
      filterCreativeByName: '',
      addGroupNumber: '',
      team: 'MM',
      flow: '',
      bidStrategy: 'LC',
      budget: '600',
      targetCPA: DEFAULT_TARGET_CPA,
      targetROAS: DEFAULT_TARGET_ROAS,
      textId: 1,
      spendingPower: 'ALL',
      age: ['AGE_35_44', 'AGE_45_54', 'AGE_55_100'],
      gender: ['GENDER_FEMALE'],
    },
    context: { currentStep },
  });

  const {
    trigger,
    reset: resetForm,
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const createTikTokAd = useCreateTikTokAd({
    config: {
      onSuccess: (r) => {
        runJubStatusCheck(r.job_id);
      },
      onError: (err) => {
        if (err.cause) {
          console.error(err.cause);
        }
      },
    },
  });

  const onSubmit = useCallback((data) => {
    const getGender = () => {
      // if selected both options - means gender All in TT.
      const isGenderMale = data.gender.includes('GENDER_MALE');
      const isGenderFemale = data.gender.includes('GENDER_FEMALE');

      return isGenderMale && isGenderFemale
        ? 'GENDER_UNLIMITED'
        : isGenderFemale
          ? 'GENDER_FEMALE'
          : 'GENDER_MALE';
    };

    // https://tanstack.com/query/latest/docs/reference/QueryClient/#queryclientgetqueriesdata
    const getCreatives = () => {
      const cachedCreativesData = queryClient.getQueriesData({
        queryKey: ['api', 'v1', 'creative_manager', 'get_creatives'],
      })[0][1];

      return data.selectedCreatives
        ? cachedCreativesData.filter((c) => data.selectedCreatives.includes(c.id))
        : null;
    };

    const getBidStrategy = () =>
      data.bidStrategy
        ? {
          ...(BID_STRATEGIES[data.bidStrategy] ?? null),
          input_value:
            data.bidStrategy === 'ROAS'
              ? parseFloat(`${data.targetROAS}`)
              : data.bidStrategy === 'CPA'
                ? parseFloat(`${data.targetCPA}`)
                : null,
        }
        : null;

    // Mutation call
    createTikTokAd.mutate({
      team: data.team,
      app: data.app,
      ad_account_id: data.adAccounts,
      ad_type: data.adType,
      campaigns: data.selectedCampaigns ?? null,
      creatives: getCreatives(),
      ad_group_id: data.addGroupNumber,
      flow_name: data.flow,
      budget: data.budget,
      age: data.age,
      gender: getGender(),
      text_id: data.textId,
      bid_strategy: getBidStrategy(),
      spending_power: data.spendingPower,
    });
  }, [createTikTokAd]);

  const handleNextStep = useCallback(() => {
    trigger(); // Manually triggers form validation.
    if (!isValid) return;
    if (currentStep === initialSteps.length - 1) {
      handleSubmit(onSubmit)();
    } else {
      nextStep();
    }
  }, [
    nextStep,
    trigger,
    handleSubmit,
    onSubmit,
    initialSteps,
    currentStep,
    isValid,
  ]);

  const handlePrevStep = useCallback(() => {
    prevStep();
  }, [prevStep]);

  const handleRestart = useCallback(() => {
    resetForm();
    resetSteps();
    clearJobStatus();
  }, [resetForm, resetSteps, clearJobStatus]);

  return (
    <StepperPaper title={'TikTok Ads Automation'} steps={initialSteps} activeStep={currentStep}>
      <>
        {isJobStatusChecking ? (
          <CircularProgress title={'Job status is checking...'} />
        ) : (
          <>
            <FormProvider {...formMethods}>
              <Suspense fallback={<CircularProgress />}>
                {isJobStatusFinished ? (
                  <JobResultTable rows={jobStatusResponse.result} />
                ) : (
                  <StepComponent />
                )}
              </Suspense>
            </FormProvider>
            <FormNavigation
              onRestart={handleRestart}
              isDisabledRestart={!currentStep && !isJobStatusFinished}

              onBack={handlePrevStep}
              isDisabledBack={!currentStep || createTikTokAd.isPending || isJobStatusFinished}

              onNext={handleNextStep}
              isDisabledNext={createTikTokAd.isPending || isJobStatusFinished}
              titleNext={currentStep === initialSteps.length - 1 ? 'Create Ad' : 'Next'}
            />
          </>
        )}
      </>
    </StepperPaper>
  );
};

export default TikTokForm;
