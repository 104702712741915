import { useFormContext } from 'react-hook-form';
import { useCampaigns } from '../api/getCampaigns';
import { useCallback, useEffect } from 'react';
import FormContainer from '../../../components/layout/FormContainer';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const ChooseCampaign = () => {
  const { watch, setValue, trigger, formState: { errors } } = useFormContext();
  const facebookAccountId = watch('adAccounts');
  const selectedCampaigns = watch('selectedCampaigns');
  const isCampaignFieldError = errors['selectedCampaigns']?.message;

  const COLUMNS = [
    { field: 'id', headerName: 'Campaign ID', width: 160 },
    { field: 'campaign_name', headerName: 'Campaign Name', width: 750 },
    { field: 'operation_status', headerName: 'Status', width: 100 },
  ];

  const { data: rows, isLoading } = useCampaigns({
    params: { facebookAccountId },
  });

  const handleSelectionMode = useCallback(
    (rows) => {
      setValue('selectedCampaigns', rows);
    },
    [setValue],
  );

  useEffect(() => {
    void trigger('selectedCampaigns');
  }, [trigger, selectedCampaigns]);

  return (
    <FormContainer title={'Choose Campaigns'} error={isCampaignFieldError}>
      <Box style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows ?? []}
          columns={COLUMNS}
          checkboxSelection
          rowSelectionModel={selectedCampaigns}
          loading={isLoading}
          onRowSelectionModelChange={handleSelectionMode}
          hideFooterPagination
        />
      </Box>
    </FormContainer>
  );
};

export default ChooseCampaign;