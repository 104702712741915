import { useWatch } from 'react-hook-form';
import { Box, InputAdornment, Skeleton, Typography } from '@mui/material';
import RadioGroup from '../../../components/common/RadioGroup';
import { AGE_RANGE_ITEMS, GENDER_ITEMS, SPENDING_POWER_ITEMS, TEAM_ITEMS } from '../../../constants';
import TextField from '../../../components/common/TextField';
import { Suspense } from 'react';
import OutlinedInput from '../../../components/common/OutlinedInput';
import ChipGroup from '../../../components/common/ChipGroup';
import { BidStrategySelect, FlowsSelect, TextsSelect } from './TikTokFormComponents';

const CreateAd = () => {
  const bidStrategy = useWatch({ name: 'bidStrategy' });
  const isTargetCPAField = bidStrategy === 'CPA';
  const isTargetROASField = bidStrategy === 'ROAS';

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Settings
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexGrow: 1,
          gap: 12,
          flexWrap: 'nowrap',
        }}
      >
        <Box sx={{ flex: 1, display: 'flex', gap: 3, flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'start',
            }}
          >
            <Typography variant="subtitle2">Team</Typography>
            <RadioGroup
              row
              name="team"
              options={TEAM_ITEMS}
              sx={{ '& .MuiButtonBase-root': { py: 0 } }}
            />
          </Box>
          <TextField name={'addGroupNumber'} inputLabel={'Add Group Number'} />
          <Suspense
            fallback={
              <Skeleton
                height={40}
                sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
              />
            }
          >
            <FlowsSelect />
          </Suspense>
          <OutlinedInput
            name={'budget'}
            label={'Budget'}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
          <Suspense
            fallback={
              <Skeleton
                height={40}
                sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
              />
            }
          >
            <TextsSelect />
          </Suspense>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              gap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Suspense
                fallback={
                  <Skeleton
                    height={40}
                    sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
                  />
                }
              >
                <BidStrategySelect />
              </Suspense>
            </Box>
            {isTargetCPAField && (
              <Box sx={{ flex: 1 }}>
                <TextField
                  name={'targetCPA'}
                  inputLabel={'Target CPA'}
                  type="number"
                />
              </Box>
            )}
            {isTargetROASField && (
              <Box sx={{ flex: 1 }}>
                <TextField
                  name={'targetROAS'}
                  inputLabel={'Target ROAS'}
                  type="number"
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="subtitle2">Spending power</Typography>
            <RadioGroup
              row
              name="spendingPower"
              options={SPENDING_POWER_ITEMS}
            />
          </Box>
        </Box>
        <Box
          sx={{
            pl: 12,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            borderLeft: `1px solid #ccc`,
          }}
        >
          <ChipGroup
            row
            name="age"
            label={'Age'}
            controlProps={{ sx: { gap: 0.5 } }}
            options={AGE_RANGE_ITEMS}
          />
          <ChipGroup
            row
            name="gender"
            label={'Gender'}
            controlProps={{ sx: { gap: 0.5 } }}
            options={GENDER_ITEMS}
          />
        </Box>
      </Box>
    </>
  );
};

export default CreateAd;