import { Box, Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { InfoOutlined } from '@mui/icons-material';

interface FormContainerProps {
  children: ReactNode;
  title: string;
  error?: string | string[];
}

const FormContainer: FC<FormContainerProps> = ({ title, error, children }) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      gap: 1,
      justifyContent: 'start',
      width: '100%',
    }}>
      <Box sx={{
        display: 'flex',
        gap: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: error ? 'space-between' : 'start',
      }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Box sx={{
          display: 'flex',
          gap: 1,
          flexDirection: 'column',
          alignItems: 'start',
        }}>
          {Array.isArray(error) ? (
            error.filter(e => !!e).map(e => (
              <Typography
                key={e}
                sx={t => ({
                  fontSize: t.typography.body2.fontSize,
                  color: t.palette.error.main,
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: .5,
                })}
                gutterBottom>
                <InfoOutlined sx={t => ({ fontSize: t.typography.body1.fontSize })} />
                {e}
              </Typography>
            ))
          ) : error ?
            <Typography
              sx={t => ({
                fontSize: t.typography.body2.fontSize,
                color: t.palette.error.main,
                display: 'inline-flex',
                alignItems: 'center',
                gap: .5,
              })}
              gutterBottom>
              <InfoOutlined sx={t => ({ fontSize: t.typography.body1.fontSize })} />
              {error}
            </Typography> : null}
        </Box>
      </Box>
      <Box sx={{
        position: 'relative',
        display: 'flex',
        flex: 1,
        width: '100%',
      }}>
        {children}
      </Box>
    </Box>
  );
};

export default FormContainer;