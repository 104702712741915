import { useFormContext } from 'react-hook-form';
import useRowsCreatives from '../../creatives/hooks/useRowsCreatives';
import { useCallback, useEffect } from 'react';
import FormContainer from '../../../components/layout/FormContainer';
import { Box } from '@mui/material';
import TextField from '../../../components/common/TextField';
import { DataGrid } from '@mui/x-data-grid';

const ChooseCreative = () => {
  const { watch, setValue, trigger, formState: { errors } } = useFormContext();

  const { rows, isLoading } = useRowsCreatives({ params: { ad_type: 'single_ad', network: 'Tik Tok' } });

  const selectedCreatives = watch('selectedCreatives');
  const isCreativesFieldError = errors['selectedCreatives']?.message;

  const COLUMNS = [
    { field: 'creative_id', headerName: 'Creative ID', width: 100 },
    { field: 'name', headerName: 'Name', width: 450 },
    { field: 'app', headerName: 'App', width: 100 },
    { field: 'creative_type', headerName: 'Type', width: 100 },
    { field: 'created', headerName: 'Created', width: 100 },
  ];

  const handleSelectionMode = useCallback(
    (rows, _) => {
      setValue('selectedCreatives', rows);
    },
    [setValue],
  );

  useEffect(() => {
    void trigger('selectedCreatives');
  }, [trigger, selectedCreatives]);

  return (
    <FormContainer title={'Choose Creatives'} error={isCreativesFieldError}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: 2, width: '100%' }}>
          <Box sx={{
            display: 'flex',
            gap: 2,
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'nowrap',
            maxWidth: '50%',
          }}>
            <TextField
              name={'filterCreativeByName'}
              placeholder={'Search by name'}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={rows ?? []}
              columns={COLUMNS}
              checkboxSelection
              rowSelectionModel={selectedCreatives}
              loading={isLoading}
              onRowSelectionModelChange={handleSelectionMode}
              hideFooterPagination
            />
          </Box>
        </Box>
      </Box>
    </FormContainer>
  );
};

export default ChooseCreative;